<template>
  <div :class="`m-3 p-2 wrapper wrapper--border wrapper--default`">
    <h3 class="text-center">File Management</h3>
    <hr>
    <Loading v-if="loading" />
    <div v-for="(category, key) in files" :key="key" class="flex _column w-100 p-2">
      <h6>{{ key }}</h6>
      <table :class="`table--default table__border--default w-100`">
        <thead :class="`table__head--default`">
          <th class="table__head--text">Filenaam</th>
          <th>Edit</th>
          <th>Delete</th>
        </thead>
        <tbody>
          <tr v-for="(file, key) in category" :key="file.id" :class="`table__row--color`">
            <td class="table__cell--default">{{ file.displayname }}</td>
            <td class="table__cell--default" style="width: 4rem;">
              <button type="button" class="employee_edit" @click="getInfoFromItem(file)" v-b-modal.edit>
                edit
              </button>
            </td>
            <td class="table__cell--default" style="width: 4rem;">
              <button type="button" class="employee_delete" @click="deleteFile(file.id, key)">
                Delete
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <b-modal id="edit" ref="edit_model">
      <template #modal-header>
        <h2>Wijzig Bestandnaam</h2>
      </template>
      <form>
        <div><input :value="this.edit_id" readonly hidden /></div>
        <div class="employee_input--container">
          <label class="employee_input--label">Bestand naam</label>
          <input v-model="edit_displayname" />
          <label class="employee_input--label">Categorie</label>
          <select name="category" id="category" v-model="edit_category">
            <option value="Algemene voorwaarden">Algemene voorwaarden</option>
            <option value="Huishoudelijk">Huishoudelijk</option>
            <option value="Compliance">Compliance</option>
            <option value="Verkoop T&T">Verkoop T&T</option>
            <option value="Verkoop Vans">Verkoop Vans</option>
            <option value="Douane">Douane</option>
            <option value="KVK">KVK</option>
            <option value="Overig">Overig</option>
          </select>
        </div>
      </form>
      <template #modal-footer>
        <div class="w-100">
          <b-button variant="primary" size="sm" class="float-right" @click="editFile()">
            Wijzig bestand
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import request from "@/functions/request.js";
import Loading from "@/components/Loading.vue";

export default {
  components: { Loading },
  data: () => ({
    files: null,
    loading: true,
    edit_id: null,
    edit_filename: null,
    edit_permissions: null,
    edit_displayname: null,
    edit_category: null,
  }),
  created() {
    this.getData();
  },
  methods: {
    getData() {
      request(`get-files`, "GET").then(({ files }) => {
        this.files = files;
        this.loading = false;
      });
    },

    deleteFile(id, key) {
      if (confirm("Bestand verwijderen?")) {
        const data = { id };
        request(`delete-file`, "DELETE", data).then(({ message }) => {
          this.$store.dispatch("addNotification", {
            message: message,
            type: "success",
          });
          this.files.splice(key, 1);
        });
      }
    },

    editFile() {
      const data = {
        id: this.edit_id,
        filename: this.edit_filename,
        permissions: this.edit_permissions,
        displayname: this.edit_displayname,
        category: this.edit_category
      };
      request("edit-file", "PATCH", data).then(() => {
        this.$store.dispatch("addNotification", {
          message: "bestand aangepast",
          type: "success",
        });
        this.$refs["edit_model"].hide();
        this.$router.go();
      });
    },

    getInfoFromItem(file) {
      this.edit_id = file.id;
      this.edit_filename = file.filename;
      this.edit_permissions = file.permissions;
      this.edit_displayname = file.displayname;
      this.edit_category = file.category;
    },
  },
};
</script>
